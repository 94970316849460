export const countries = {
  id: 'countries',
  type: 'fill',
  source: 'ipc',
  'source-layer': 'countries',
  layout: {},
  paint: {
    'fill-color': '#333333',
    'fill-opacity': ['case', ['==', ['get', 'mask'], true], 0.5, 0],
  },
};

export const chCountries = {
  id: 'chcountries',
  type: 'fill',
  source: 'ipc',
  'source-layer': 'chcountries',
  layout: {},
  paint: {
    'fill-opacity': 0,
  },
};

export const chCountriesBorder = {
  id: 'chcountriesBorder',
  type: 'line',
  source: 'ipc',
  'source-layer': 'chcountries',
  layout: { 'line-cap': 'round', 'line-join': 'round' },
  paint: {
    'line-color': '#95e3fc',
    'line-width': 10,
  },
};

export const countriesHighlight = {
  id: 'countries-highlight',
  type: 'line',
  source: 'ipc',
  'source-layer': 'countries',
  paint: {
    'line-color': '#000000',
    'line-width': ['interpolate', ['linear'], ['zoom'], 3, 2, 6, 3],
    'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0],
  },
};

export const countriesMask = {
  id: 'countries-mask',
  type: 'fill',
  source: 'ipc',
  'source-layer': 'mask',
  paint: {
    'fill-color': '#ffffff',
    'fill-opacity': 0,
  },
};

export const areasHighlight = {
  id: 'areas-highlight',
  type: 'line',
  source: 'ipc',
  'source-layer': 'areas',
  layout: {},
  paint: {
    'line-color': '#000000',
    'line-width': 2,
    'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0],
  },
};

export const areas = {
  id: 'areas',
  type: 'fill',
  source: 'ipc',
  'source-layer': 'areas',
  layout: {},
  paint: {
    'fill-color': [
      'case',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 1]],
      '#cef9ce',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 2]],
      '#f9e43c',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 3]],
      '#e4781f',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 4]],
      '#c60813',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 5]],
      '#640000',
      ['all', ['==', ['get', 'condition'], 'C'], ['==', ['get', 'overall_phase'], 1]],
      'rgb(204, 255, 204)',
      ['all', ['==', ['get', 'condition'], 'C'], ['==', ['get', 'overall_phase'], 2]],
      'rgb(203, 201, 226)',
      ['all', ['==', ['get', 'condition'], 'C'], ['==', ['get', 'overall_phase'], 3]],
      'rgb(158, 154, 200)',
      ['all', ['==', ['get', 'condition'], 'C'], ['==', ['get', 'overall_phase'], 4]],
      'rgb(106, 81, 163)',
      ['==', ['get', 'overall_phase'], 9],
      '#a6a6a6',
      'hsl(0, 0%, 100%)',
    ],
    'fill-antialias': false,
    'fill-opacity': 0.9,
  },
};

export const areasGroup = {
  id: 'areasGroup',
  type: 'fill',
  source: 'ipc',
  'source-layer': 'groups',
  layout: {},
  paint: {
    'fill-color': [
      'case',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 1]],
      '#cef9ce',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 2]],
      '#f9e43c',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 3]],
      '#e4781f',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 4]],
      '#c60813',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 5]],
      '#640000',
      ['all', ['==', ['get', 'condition'], 'C'], ['==', ['get', 'overall_phase'], 1]],
      'rgb(204, 255, 204)',
      ['all', ['==', ['get', 'condition'], 'C'], ['==', ['get', 'overall_phase'], 2]],
      'rgb(203, 201, 226)',
      ['all', ['==', ['get', 'condition'], 'C'], ['==', ['get', 'overall_phase'], 3]],
      'rgb(158, 154, 200)',
      ['all', ['==', ['get', 'condition'], 'C'], ['==', ['get', 'overall_phase'], 4]],
      'rgb(106, 81, 163)',
      ['==', ['get', 'overall_phase'], 9],
      '#a6a6a6',
      'hsl(0, 0%, 100%)',
    ],
    'fill-antialias': false,
    'fill-opacity': 0.9,
  },
};

export const areasFamine = {
  id: 'areasFamine',
  type: 'fill',
  source: 'ipc',
  'source-layer': 'areas',
  layout: {},
  paint: {
    'fill-pattern': ['match', ['get', 'overall_phase'], [6], 'famine', ''],
  },
};

export const areasGroupFamine = {
  id: 'areasGroupFamine',
  type: 'fill',
  source: 'ipc',
  'source-layer': 'groups',
  layout: {},
  paint: {
    'fill-pattern': ['match', ['get', 'overall_phase'], [6], 'famine', ''],
  },
};

export const urbanAreas = {
  id: 'urbanAreas',
  type: 'circle',
  source: 'ipc',
  'source-layer': 'areas',
  layout: {},
  paint: {
    'circle-color': [
      'case',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 1]],
      '#cef9ce',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 2]],
      '#f9e43c',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 3]],
      '#e4781f',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 4]],
      '#c60813',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 5]],
      '#640000',
      ['all', ['==', ['get', 'condition'], 'C'], ['==', ['get', 'overall_phase'], 1]],
      'rgb(204, 255, 204)',
      ['all', ['==', ['get', 'condition'], 'C'], ['==', ['get', 'overall_phase'], 2]],
      'rgb(203, 201, 226)',
      ['all', ['==', ['get', 'condition'], 'C'], ['==', ['get', 'overall_phase'], 3]],
      'rgb(158, 154, 200)',
      ['all', ['==', ['get', 'condition'], 'C'], ['==', ['get', 'overall_phase'], 4]],
      'rgb(106, 81, 163)',
      ['==', ['get', 'overall_phase'], 9],
      '#a6a6a6',
      'hsl(0, 0%, 100%)',
    ],
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 3, 2, 12, 20],
    'circle-opacity': 0.75,
    'circle-stroke-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      '#000000',
      '#000000',
    ],
    'circle-stroke-width': ['interpolate', ['linear'], ['zoom'], 3, 0, 12, 2],
  },
};

export const urbanAreasCenter = {
  id: 'urbanAreasCenter',
  type: 'circle',
  source: 'ipc',
  'source-layer': 'areas',
  layout: {},
  paint: {
    'circle-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#ffffff', '#000000'],
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 3, 1, 12, 8],
  },
};

export const idp = {
  id: 'idp',
  type: 'symbol',
  source: 'ipc',
  'source-layer': 'areas',
  layout: {
    'icon-image': ['concat', 'idp-camp', ['to-string', ['get', 'overall_phase']]],
    'icon-size': ['interpolate', ['linear'], ['zoom'], 3, 0.15, 12, 2],
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
  },
  paint: {
    // 'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0, 1],
  },
};

export const idpHover = {
  id: 'idpHover',
  type: 'symbol',
  source: 'ipc',
  'source-layer': 'areas',
  layout: {
    'icon-image': ['concat', 'hover-idp-camp', ['to-string', ['get', 'overall_phase']]],
    'icon-size': ['interpolate', ['linear'], ['zoom'], 3, 0.15, 12, 2],
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
  },
  paint: {
    'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0],
  },
};

export const hhg = {
  id: 'hhg',
  type: 'symbol',
  source: 'ipc',
  'source-layer': 'areas',
  layout: {
    'icon-image': ['concat', 'hhg', ['to-string', ['get', 'overall_phase']]],
    'icon-size': ['interpolate', ['linear'], ['zoom'], 3, 0.025, 12, 0.25],
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
  },
  paint: {
    // 'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0, 1],
  },
};

export const hhgHover = {
  id: 'hhgHover',
  type: 'symbol',
  source: 'ipc',
  'source-layer': 'areas',
  layout: {
    'icon-image': ['concat', 'hover-hhg', ['to-string', ['get', 'overall_phase']]],
    'icon-size': ['interpolate', ['linear'], ['zoom'], 3, 0.025, 12, 0.25],
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
  },
  paint: {
    'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0],
  },
};

export const rfg = {
  id: 'rfg',
  type: 'symbol',
  source: 'ipc',
  'source-layer': 'areas',
  minzoom: 4,
  layout: {
    'icon-image': ['concat', 'rfg', ['to-string', ['get', 'overall_phase']]],
    'icon-size': ['interpolate', ['linear'], ['zoom'], 3, 0.05, 12, 0.8],
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
  },
  paint: {
    // 'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0, 1],
  },
};

export const rfgHover = {
  id: 'rfgHover',
  type: 'symbol',
  source: 'ipc',
  'source-layer': 'areas',
  minzoom: 4,
  layout: {
    'icon-image': ['concat', 'hover-rfg', ['to-string', ['get', 'overall_phase']]],
    'icon-size': ['interpolate', ['linear'], ['zoom'], 3, 0.05, 12, 0.8],
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
  },
  paint: {
    'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0],
  },
};

export const rfgCA = {
  id: 'rfg',
  type: 'symbol',
  source: 'ipc',
  'source-layer': 'areas',
  layout: {
    'icon-image': ['concat', 'rfg', ['to-string', ['get', 'overall_phase']]],
    'icon-size': ['interpolate', ['linear'], ['zoom'], 3, 0.25, 12, 0.8],
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
  },
  paint: {
    // 'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0, 1],
  },
};

export const rfgHoverCA = {
  id: 'rfgHover',
  type: 'symbol',
  source: 'ipc',
  'source-layer': 'areas',
  layout: {
    'icon-image': ['concat', 'hover-rfg', ['to-string', ['get', 'overall_phase']]],
    'icon-size': ['interpolate', ['linear'], ['zoom'], 3, 0.25, 12, 0.8],
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
  },
  paint: {
    'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0],
  },
};

export const urbanAreasGroup = {
  id: 'urbanAreasGroup',
  type: 'circle',
  source: 'ipc',
  'source-layer': 'groups_points',
  layout: {},
  paint: {
    'circle-color': [
      'case',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 1]],
      '#cef9ce',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 2]],
      '#f9e43c',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 3]],
      '#e4781f',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 4]],
      '#c60813',
      ['all', ['==', ['get', 'condition'], 'A'], ['==', ['get', 'overall_phase'], 5]],
      '#640000',
      ['all', ['==', ['get', 'condition'], 'C'], ['==', ['get', 'overall_phase'], 1]],
      'rgb(204, 255, 204)',
      ['all', ['==', ['get', 'condition'], 'C'], ['==', ['get', 'overall_phase'], 2]],
      'rgb(203, 201, 226)',
      ['all', ['==', ['get', 'condition'], 'C'], ['==', ['get', 'overall_phase'], 3]],
      'rgb(158, 154, 200)',
      ['all', ['==', ['get', 'condition'], 'C'], ['==', ['get', 'overall_phase'], 4]],
      'rgb(106, 81, 163)',
      ['==', ['get', 'overall_phase'], 9],
      '#a6a6a6',
      'hsl(0, 0%, 100%)',
    ],
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 3, 2, 12, 20],
    'circle-opacity': 0.75,
    'circle-stroke-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      '#000000',
      '#000000',
    ],
    'circle-stroke-width': ['interpolate', ['linear'], ['zoom'], 3, 0, 12, 2],
  },
};

export const urbanAreasCenterGroup = {
  id: 'urbanAreasCenterGroup',
  type: 'circle',
  source: 'ipc',
  'source-layer': 'groups_points',
  layout: {},
  paint: {
    'circle-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#ffffff', '#000000'],
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 3, 1, 12, 8],
  },
};

export const idpGroup = {
  id: 'idpGroup',
  type: 'symbol',
  source: 'ipc',
  'source-layer': 'groups_points',
  layout: {
    'icon-image': ['concat', 'idp-camp', ['to-string', ['get', 'overall_phase']]],
    'icon-size': ['interpolate', ['linear'], ['zoom'], 3, 0.15, 12, 2],
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
  },
  paint: {
    // 'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0, 1],
  },
};

export const idpHoverGroup = {
  id: 'idpHoverGroup',
  type: 'symbol',
  source: 'ipc',
  'source-layer': 'groups_points',
  layout: {
    'icon-image': ['concat', 'hover-idp-camp', ['to-string', ['get', 'overall_phase']]],
    'icon-size': ['interpolate', ['linear'], ['zoom'], 3, 0.15, 12, 2],
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
  },
  paint: {
    'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0],
  },
};

export const hhgGroup = {
  id: 'hhgGroup',
  type: 'symbol',
  source: 'ipc',
  'source-layer': 'groups_points',
  layout: {
    'icon-image': ['concat', 'hhg', ['to-string', ['get', 'overall_phase']]],
    'icon-size': ['interpolate', ['linear'], ['zoom'], 3, 0.025, 12, 0.25],
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
  },
  paint: {
    // 'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0, 1],
  },
};

export const hhgHoverGroup = {
  id: 'hhgHoverGroup',
  type: 'symbol',
  source: 'ipc',
  'source-layer': 'groups_points',
  layout: {
    'icon-image': ['concat', 'hover-hhg', ['to-string', ['get', 'overall_phase']]],
    'icon-size': ['interpolate', ['linear'], ['zoom'], 3, 0.025, 12, 0.25],
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
  },
  paint: {
    'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0],
  },
};

export const areasBorder = {
  id: 'areas-border',
  type: 'line',
  source: 'ipc',
  'source-layer': 'areas',
  layout: {},
  paint: {
    'line-color': '#ffffff',
    'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 4, 0.05, 8, 2],
    'line-opacity': ['case', ['==', ['get', 'hide_area_borders'], true], 0, 1],
  },
};

export const areasGroupBorder = {
  id: 'areas-group-border',
  type: 'line',
  source: 'ipc',
  'source-layer': 'groups',
  layout: {},
  paint: {
    'line-color': '#ffffff',
    'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 4, 0.05, 8, 2],
    'line-opacity': ['case', ['==', ['get', 'hide_area_borders'], true], 0, 1],
  },
};

export const areasLabels = {
  id: 'areas-label',
  type: 'symbol',
  source: 'ipc',
  'source-layer': 'labels',
  minzoom: 4.2,
  layout: {
    'text-field': ['to-string', ['get', 'title']],
    'text-font': ['Myriad Pro Regular', 'Arial Unicode MS Regular'],
    'text-size': ['interpolate', ['exponential', 1.5], ['zoom'], 5, 12, 9, 18],
    'text-padding': 8,
  },
  paint: {
    'text-opacity': ['case', ['==', ['get', 'hide_area_labels'], true], 0, 1],
    'text-halo-color': 'hsl(0, 0%, 100%)',
    'text-color': 'hsl(0, 0%, 0%)',
    'text-halo-width': 1,
  },
};

export const areasGroupLabels = {
  id: 'areas-group-label',
  type: 'symbol',
  source: 'ipc',
  'source-layer': 'groups_labels',
  minzoom: 5,
  layout: {
    'text-field': ['to-string', ['get', 'title']],
    'text-font': ['Myriad Pro Regular', 'Arial Unicode MS Regular'],
    'text-size': ['interpolate', ['exponential', 1.5], ['zoom'], 5, 12, 9, 18],
    'text-padding': 8,
    'text-variable-anchor': ['bottom-left', 'center'],
  },
  paint: {
    'text-opacity': ['case', ['==', ['get', 'hide_area_labels'], true], 0, 1],
    'text-halo-color': 'hsl(0, 0%, 100%)',
    'text-color': 'hsl(0, 0%, 0%)',
    'text-halo-width': 1,
  },
};

export const bags = {
  id: 'bags',
  source: 'ipc',
  type: 'symbol',
  'source-layer': 'icons',
  layout: {
    'icon-image': ['to-string', ['get', 'icon']],
    'icon-size': ['interpolate', ['linear'], ['zoom'], 3, 0.05, 8, 0.4],
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
    'icon-offset': ['literal', [20, -20]], // Adjust the offset values (X, Y)
  },
};

export const rfm = {
  id: 'rfm',
  source: 'ipc',
  type: 'symbol',
  'source-layer': 'icons',
  layout: {
    'icon-image': ['to-string', ['get', 'icon']],
    'icon-size': ['interpolate', ['linear'], ['zoom'], 3, 0.05, 8, 0.2],
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
  },
};
